.button {
	display: flex;
	align-items: center;
	justify-content: center;
	letter-spacing: 0.02em;
}

.button_size-s {
	border-radius: 16px;
	font-size: 12px;
	font-weight: bold;
	height: 36px;
	padding: 0 16px;
}

.button_size-l {
	border-radius: 34px;
	font-size: 22px;
	font-weight: bold;
	height: 68px;
	padding: 0 18px;
}

.button_color-b {
	background-color: $color-primary-blue;
	border-radius: 4px;
	color: $color-white;
}

.button_color-r {
	background-color: $color-interface-red;
	color: $color-white;
}

.button_color-accent {
	background: linear-gradient(90deg, #FF4C64 0%, #FF8A84 100%);
	color: $color-white;
}

.button_w100 {
	width: 100%;
}
