html {
	background-color: $color-white;
	font-family: $font-primary;
	font-size: 16px;
	line-height: 1.6;
	color: $color-black;
}

body * {
	box-sizing: border-box;
}

.landing {
	@include absolute($top: 0, $left: 0);
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
