.header {
	flex: 1 0 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	background: linear-gradient(180deg, rgba(199, 0, 33, 0.6) 0%, rgba(0, 0, 0, 0.15) 100%), url("../images/bg.jpg") center/cover;
	padding: 0 20px;
	text-align: center;

	&__logo {
		max-width: 100%;
		margin-bottom: 26px;
	}

	&__slogan {
		font-family: $font-secondary;
		font-size: 36px;
		color: $color-white;
		margin-bottom: 52px;
	}

	&__heart {
		@include absolute($top: 35px, $left: 35px);
	}

	&__banner {
		@include absolute($top: 0, $left: 0);
		width: 100%;
	}

	&-support-button{
		margin-top: 36px;
		padding: 4px 16px;
		background: rgba(#fff, .9);
		border-radius: 32px;
		border: 1px solid #fff;
	}
}

@include table-screen() {
	.header {
		&__logo {
			margin-bottom: 40px;
		}

		&__slogan {
			font-size: 28px;
			margin-bottom: 46px;

			.underline::before {
				right: -20px;
				width: 124px;
				height: 10px;
			}
		}
	}
}

.mobile {
	.header {
		padding-bottom: 62px;
		background: linear-gradient(180deg, rgba(199, 0, 33, 0.6) 0%, rgba(0, 0, 0, 0.15) 100%), url("../images/bg_m.jpg") top/cover;
		justify-content: flex-end;
	}
}

.store-banner {
	display: flex;
	align-items: center;
	background-color: $color-white;
	text-align: left;
	padding: 0 12px 0 8px;
	height: 80px;
	overflow: hidden;

	&__logo {
		flex-shrink: 0;
		font-size: 0;
		margin-right: 8px;
	}

	&__content {
		flex: 1 1 auto;
		line-height: 1.16;
		margin-right: 4px;
	}

	&__title {
		font-size: 12px;
		margin-bottom: 4px;
	}

	&__desc {
		font-size: 11px;
		color: $color-gray3;
		margin-bottom: 4px;
	}
}
