.overflow-h {
	overflow: hidden;
	overflow-x: scroll;
	-webkit-overflow-scrolling: touch;
}

.overflow-v {
	overflow: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}