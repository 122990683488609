.footer {
	flex-shrink: 0;
	background-color: $color-black;
	padding: 44px 0 180px 0;

	&__logo {
		margin-top: -12px;
	}

	&__links {
		justify-self: end;
		grid-column: 2 / 11;
		display: flex;
		align-items: center;
		color: #ffe9e9;
		white-space: nowrap;

		a {
			display: inline-block;
			margin-left: 60px;
		}
	}

	&__social {
		grid-column: 12;
		justify-self: end;
		display: flex;
		align-items: center;
		column-gap: 4px;
		font-size: 16px;
		font-weight: 600;
		color: $color-white;
		white-space: nowrap;
	}
}

@include table-screen() {
	.footer {
		padding: 32px 20px 98px;

		.container {
			align-items: flex-start;
		}

		&__logo {
			margin: 0 0 60px;
		}

		&__links {
			flex-direction: column;
			row-gap: 20px;
			align-items: flex-start;
			margin: 0 0 40px 4px;

			a {
				margin: 0;
			}
		}
	}
}

@include mobile-screen() {
	.footer {
		padding-left: 0;
		padding-right: 0;

		&__logo {
			width: 190px;
			height: 32px;
		}

		&__links {
			font-size: 14px;
		}
	}
}
