.container {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	column-gap: 20px;
	text-align: center;
	padding: 0 40px;
	max-width: 1440px;
	margin: 0 auto;

	&__wrap {
		grid-column: 3 / span 8;
	}
}

@include table-screen() {
	.container {
		display: flex;
		flex-direction: column;
		padding: 0 20px;
	}
}
