$font-primary: 'Lato', sans-serif;
$font-secondary: 'Oooh Baby', cursive;

$color-primary-yellow-anabled: #ffba33;
$color-primary-yellow-tapped: #e49700;
$color-primary-yellow-disabled: #ffe7b7;
$color-gray0-5: #fafafa;
$color-gray4: #4a4a4a;


$color-black: #000000;
$color-white: #fff;
$color-light-red: #fff2f2;
$color-interface-red: #ff596b;
$color-primary-pink: #ff3052;
$color-primary-blue: #228dfa;
$color-yellow: #ffca51;
$color-gray1: #212121;
$color-gray2: #303030;
$color-gray3: #999999;
