.underline {
	position: relative;
	white-space: nowrap;

	&::before {
		@include absolute($top: 100%, $right: -60px);
		content: '';
		background-color: $color-primary-pink;
		mask: url("../images/underline.svg") center/contain no-repeat;
		width: 191px;
		height: 13px;
	}
}

.strokes {
	position: relative;
	white-space: nowrap;
	z-index: -1;

	&::before {
		@include absolute($top: 50%, $left: 50%);
		content: '';
		background: url("../images/strokes.svg") center/contain no-repeat;
		width: 329px;
		height: 112px;
		transform: translate(-50%, calc(-50% + 10px));
		z-index: -1;
	}
}

@include table-screen() {
	.strokes::before {
		width: 270px;
		height: 92px;
	}
}

@include mobile-screen() {
	.strokes::before {
		width: 161px;
		height: 54px;
	}
}
