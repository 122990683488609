@mixin mobile-screen() {
	@media (max-width: 480px) {
		@content;
	}
}

@mixin table-screen() {
	@media (max-width: 960px) {
		@content;
	}
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
	position: $position;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin absolute($args...) {
	@include position(absolute, $args...);
}

@mixin sticky($args...) {
	@include position(sticky, $args...);
}
