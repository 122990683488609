.main-content {
	flex-shrink: 0;
	position: relative;
	background-color: $color-light-red;
	padding-bottom: 270px;
	margin-top: -100vh;

	h2 {
		font-size: 48px;
	}

	h3 {
		font-size: 28px;
	}

	h4 {
		font-size: 20px;
	}
}

.section-intro {
	background: url("../images/quote.svg") center/400px 370px no-repeat;
	padding: 288px 0;

	h2 {
		position: relative;
		color: $color-gray1;
		margin-bottom: 44px;
		z-index: 1;
	}

	p {
		font-size: 24px;
		color: $color-gray2;
	}
}

.section-profile {
	position: relative;
	color: $color-white;
	padding: 160px 0 0;
	margin-bottom: 130px;
	overflow: hidden;
	z-index: 1;

	&::before {
		@include absolute($top: 0, $left: 0);
		content: '';
		background: linear-gradient(131.74deg, #FA5278 0%, #F0332D 94.78%);
		border-radius: 0 0 164px 164px;
		width: 100%;
		height: 85%;
		transform: skewY(-10deg);
		transform-origin: top left;
		z-index: -1;
	}

	h2 {
		margin-bottom: 60px;

		.underline::before {
			background-color: $color-yellow;
			width: 300px;
			height: 21px;
		}
	}

	p {
		font-size: 24px;
		margin-bottom: 40px;
	}

	&__image {
		background: url("../images/profile.png") center/contain no-repeat;
		height: 830px;
	}
}

.section-match {
	position: relative;
	padding-top: 44px;
	overflow: hidden;
	z-index: 1;

	&::before {
		@include absolute($bottom: 0, $left: 0);
		content: '';
		background: $color-white;
		border-radius: 164px 164px 0 0;
		width: 100%;
		height: 70%;
		transform: skewY(-8deg);
		transform-origin: top right;
		z-index: -1;
	}

	h2 {
		margin: 0 0 124px -20px;
		line-height: 1.83;

		span {
			display: inline-block;
			background-color: $color-white;
			border-radius: 32px;
			padding: 0 20px;
			white-space: nowrap;
		}

		mark {
			display: inline-block;
			background: none;
			color: $color-primary-pink;
			text-transform: uppercase;
		}
	}

	p {
		font-size: 24px;
		color: $color-gray1;
	}

	p + p {
		margin-top: 1.6em;
	}

	&__image {
		position: relative;
		grid-column: 1 / span 6;
		background: url("../images/match.png") top center/contain no-repeat;
		height: 729px;

		&::before, &::after {
			content: '';
			z-index: -1;
		}

		&::before {
			@include absolute($top: 94px, $right: 50%);
			background: url("../images/line_left.svg") center/contain no-repeat;
			width: 948px;
			height: 437px;
		}

		&::after {
			@include absolute($top: -10px, $left: calc(50% - 80px));
			background: url("../images/line_right.svg") center/contain no-repeat;
			width: 1651px;
			height: 600px;
		}
	}

	&__content {
		grid-column: 7 / span 5;
		text-align: left;
		padding-top: 38px;
	}
}

.section-features {
	position: relative;
	background-color: $color-white;
	row-gap: 68px;
	padding: 120px 0 160px;
	margin-bottom: 150px;
}

.feature {
	grid-column: 2 / span 5;
	text-align: left;
	padding: 0 48px;

	&__icon {
		width: 154px;
		height: 154px;
		margin: 0 0 -12px -32px;
	}

	&__title {
		margin-bottom: 12px;
	}

	&__desc {
		font-size: 20px;
		color: $color-gray2;
	}

	&:nth-child(even) {
		grid-column-start: 7;
	}
}

.section-stories {
	margin-bottom: 388px;

	h2 {
		margin-bottom: 44px;

		.underline {
			color: $color-primary-pink;

			&::before {
				width: 368px;
				height: 24px;
			}
		}
	}

	p {
		font-size: 20px;
		color: $color-gray2;
		line-height: 1.6;
		margin-bottom: 60px;
	}

	a {
		color: $color-primary-pink;
		font-weight: 600;

		&::after {
			content: '';
			display: inline-block;
			border: 3px solid currentColor;
			border-top: none;
			border-left: none;
			width: 10px;
			height: 10px;
			transform: rotate(-45deg);
			margin-left: 8px;
		}
	}

	&__list {
		position: relative;
		grid-column: 2 / span 10;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
		margin-top: 60px;

		&::before {
			@include absolute($top: -80px, $right: -110px);
			content: '';
			background: url("../images/hook.svg") top center/contain no-repeat;
			width: 244px;
			height: 222px;
		}
	}
}

.review {
	display: flex;
	align-items: center;
	background-color: $color-white;
	border-radius: 40px;
	padding: 20px 10px 18px 20px;

	&__photo {
		border-radius: 22%;
		width: 35%;
		height: auto;
		margin-right: 30px;
	}

	&__content {
		text-align: left;
	}

	&__text {
		font-size: 18px;
		line-height: 1.55;
		font-weight: bold;
		color: $color-gray2;
		margin-bottom: 14px;
		padding-right: 10px;
	}

	&__info {
		display: inline-block;
		border-radius: 90px;
		color: $color-white;
		margin-left: -12px;
		padding: 10px 16px 9px 12px;
	}

	&:nth-child(odd) {
		border-bottom-left-radius: 0;
	}

	&:nth-child(even) {
		border-bottom-right-radius: 0;
	}

	&:nth-child(1) {
		transform: translateX(-20px);

		.review__info {
			background: linear-gradient(90deg, #1F8EFA 0%, #5EA3E6 100%);
		}
	}

	&:nth-child(2) {
		transform: translateX(-20px);

		.review__info {
			background: linear-gradient(90deg, #9368E9 0%, #B170CD 100%);
		}
	}

	&:nth-child(3) {
		transform: translateX(20px);

		.review__info {
			background: linear-gradient(90deg, #F6465A 0%, #E03AC0 100%);
		}
	}

	&:nth-child(4) {
		transform: translateX(20px);

		.review__info {
			background: linear-gradient(90deg, #FF6F8E 0%, #FF836C 100%);
		}
	}
}

.section-promo {
	h2 {
		position: relative;
		margin-bottom: 80px;
		z-index: 1;

		mark {
			position: relative;
			background: linear-gradient(89.66deg, #FF7354 0.29%, #FF3052 51.04%, #FF1B5F 99.71%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			padding: 0 26px;
			margin: 0 16px;
			white-space: nowrap;

			&::before {
				@include absolute($top: -9px, $right: 0, $bottom: -21px, $left: 0);
				content: '';
				display: inline-block;
				background-color: $color-white;
				border-radius: 32px;
				box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05);
				z-index: -1;
			}

			&::after {
				@include absolute($top: -84px, $right: -48px);
				content: '';
				background: url("../images/like_baloon.png") top center/contain no-repeat;
				width: 113px;
				height: 113px;
			}
		}
	}

	p {
		font-size: 24px;
		margin-bottom: 58px;
	}
}

@include table-screen() {
	.main-content {
		padding-bottom: 142px;

		h2 {
			font-size: 36px;
		}

		h3 {
			font-size: 26px;
		}

		h4 {
			font-size: 18px;
		}
	}

	.section-intro {
		padding: 132px 0;
		background-size: 223px 206px;
		background-position-y: calc(50% + 50px);

		h2 {
			margin-bottom: 40px;
		}

		p {
			font-size: 20px;
		}
	}

	.section-profile {
		padding: 80px 0 0;
		margin-bottom: 80px;

		&::before {
			border-radius: 0 0 98px 98px;
		}

		h2 {
			margin-bottom: 28px;

			.underline::before {
				right: -50px;
				width: 234px;
				height: 16px;
			}
		}

		p {
			font-size: 20px;
			margin-bottom: 40px;
		}

		&__image {
			background: url("../images/profile.png") center/contain no-repeat;
			padding-bottom: 84%;
			height: auto;
		}
	}

	.section-match {
		&::before {
			border-radius: 98px 98px 0 0;
			height: 42%;
		}

		h2 {
			margin-bottom: 28px;

			mark::before {
				border-radius: 22px;
				top: 1px;
				bottom: 1px
			}
		}

		p {
			font-size: 20px;
		}

		&__image {
			height: auto;
			margin: 0 -20px;
			padding-bottom: 124%;
		}

		&__content {
			order: -1;
			text-align: center;
			padding-top: 0;
			margin-bottom: 40px;
		}
	}

	.section-features {
		row-gap: 40px;
		padding: 40px 0 88px;
		margin-bottom: 80px;

		&::after {
			@include absolute($bottom: 0, $left: 50%);
			content: '';
			background: url("../images/hook_m.svg") top center/contain no-repeat;
			width: 100px;
			height: 100px;
			transform: translate(-50%, calc(50% + 10px));
		}
	}

	.feature {
		text-align: center;
		padding: 0 20px;

		&__icon {
			width: 141px;
			height: 141px;
			margin: 0;
		}

		&__title {
			margin-bottom: 20px;
		}

		&__desc {
			font-size: 18px;
		}

		&:nth-child(1) {
			order: 1;
		}

		&:nth-child(2) {
			order: 3;
		}

		&:nth-child(3) {
			order: 2;
		}

		&:nth-child(4) {
			order: 4;
		}
	}

	.section-stories {
		margin-bottom: 142px;

		h2 {
			margin-bottom: 28px;

			.underline::before {
				right: -30px;
				width: 266px;
				height: 17px;
			}
		}

		p {
			font-size: 18px;
			margin-bottom: 36px;
		}

		a::after {
			border-width: 2px;
			width: 8px;
			height: 8px;
			margin-left: 4px;

		}

		&__list {
			display: flex;
			flex-direction: column;
			row-gap: 60px;
			margin-top: 72px;

			&::before {
				content: none;
			}
		}
	}

	.review {
		flex-direction: column;
		border: 1px solid #f0f0f0;
		border-radius: 16px !important;
		padding: 0 24px 20px;
		transform: none !important;

		&__photo {
			border-radius: 20px;
			width: 88px;
			height: 88px;
			margin: -32px 0 12px 0;
		}

		&__content {
			text-align: center;
		}

		&__text {
			font-size: 16px;
		}
	}

	.section-promo {
		h2 {
			margin-bottom: 40px;

			mark {
				padding: 0 20px;
				margin: 0 4px;

				&::before {
					top: -2px;
					bottom: -10px;
				}

				&::after {
					top: -36px;
					right: -16px;
					width: 57px;
					height: 57px;
				}
			}
		}

		p {
			font-size: 20px;
			margin-bottom: 40px;
		}
	}
}

@include mobile-screen() {
	.main-content {
		h2, h3 {
			font-size: 24px;
		}

		h4 {
			font-size: 16px;
		}
	}

	.section-intro {
		p {
			font-size: 16px;
		}
	}

	.section-profile {
		padding-bottom: 60px;
		margin-bottom: 20px;

		&::before {
			border-radius: 0 0 40px 40px;
			height: 74%;
		}

		&::after {
			@include absolute($bottom: 0, $left: -20px);
			content: '';
			background: url("../images/line_bottom.svg");
			width: 525px;
			height: 151px;
			z-index: -1;
		}

		h2 {
			.underline::before {
				right: -30px;
				width: 160px;
				height: 12px;
			}
		}

		p {
			font-size: 16px;
		}

		&__image {
			background: url("../images/profile_m.png") center/contain no-repeat;
			padding-bottom: 87%;
			height: auto;
		}
	}

	.section-match {
		&::before {
			border-radius: 40px 40px 0 0;
			height: 34%;
		}

		h2 {
			mark {
				padding: 0 8px;

				&::before {
					border-radius: 12px;
					top: 2px;
					right: 0;
					bottom: 2px;
					left: 0;
				}
			}
		}

		p {
			font-size: 16px;
		}

		&__image {
			background: url("../images/match_m.png") center/contain no-repeat;
			padding-bottom: 157%;

			&::before {
				width: 668px;
				height: 308px;
			}

			&::after {
				top: 30px;
				left: calc(50% - 40px);
				width: 1140px;
				height: 414px;
				z-index: -2;
			}
		}
	}

	.feature {
		&__icon {
			width: 128px;
			height: 128px;
		}

		&__desc {
			font-size: 16px;
		}
	}

	.section-stories {
		h2 {
			.underline::before {
				width: 184px;
				height: 11px;
			}
		}

		p {
			font-size: 16px;
		}

		&__list {
			margin-right: -8px;
			margin-left: -8px;
		}
	}

	.review {
		&__content {
			text-align: center;
		}

		&__text, &__info {
			font-size: 14px;
		}
	}

	.section-promo {
		h2 {
			display: flex;
			flex-direction: column;
			align-items: center;

			mark::before {
				bottom: -4px;
			}
		}

		p {
			font-size: 16px;
		}
	}
}


