//@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Oooh+Baby&display=swap');

@font-face {
	font-family: 'Lato';
	src: url("../fonts/Lato-Regular.ttf");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url("../fonts/Lato-Bold.ttf");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Oooh Baby';
	src: url("../fonts/OoohBaby-Regular.ttf");
	font-weight: normal;
	font-style: normal;
}
