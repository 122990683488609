h1, h2, h3, h4, h5, h6, p, a, ol, ul, li {
	margin: 0;
	padding: 0;
	vertical-align: baseline;
}

a {
	color: inherit;
	text-decoration: none;
	-webkit-tap-highlight-color: transparent;
}

button {
	background-color: transparent;
	outline: none;
	border: 0;
	cursor: pointer;
	padding: 0;
	-webkit-tap-highlight-color: transparent;
}

input {
	padding: 0;
}

ul {
	list-style: none;
	-webkit-tap-highlight-color: transparent;
}
