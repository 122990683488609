.store-badges {
	display: flex;
	justify-content: center;

	&__item {
		text-align: center;
		margin: 0 14px;

		a {
			display: block;
			font-size: 0;
			margin-bottom: 24px;
		}
	}
}

.store-badges__support{

}
